<template>
  <div id="index" class="template7_container">
    <!-- 头部 -->
    <Menu :actived="1" />
    <!-- 头部 -->
    <div class="bannerBox" v-if="thumbShow">
      <div class="viewBtn" @click="handleCloseBanner">
        关闭预览
      </div>
      <div class="viewBtn mt-1" @click="handleShowOrign">
        查看原图
      </div>
      <div class="viewBtn mt-2" @click="handleShowPrev">
        上一张
      </div>
      <div class="viewBtn mt-3" @click="handleShowNext">
        下一张
      </div>
      <div class="viewPic">
        <img :src="thumbLink">
      </div>
      <div class="viewList">
        <div @click.stop="handleChooseShow(item,index)" v-for="(item,index) in content" :key="index">
          <img :class="currentKey == index ? 'selected' : ''" :src="item + '/applet_suo'">
        </div>
      </div>
    </div>

    <div class="template7_blog_sitecontent">
      <div>
        <div>
          <div class="workListWrapTemplate workListWrapTemplate2">
            <div class="workContentWrap">
              <div class="workTile workTile_5"><a>{{ title }}</a></div>
              <!-- <div class="workCreatedAt">October 16, 2019</div> -->
              <div class="work_title_border"></div>
              <!-- <pre class="workContent workContent_5">2019/08/24 GUIYANG<a class="more_title_guide"></a></pre> -->
            </div>

            <div>
                <a class="workImage_wrap workImage_wrap_item" v-for="(item,index) in content" :key="item.id">
                  <img @click="handleShowThumb(item,index)" v-if="!item.toLowerCase().includes('.mp4')" :src="item + '/applet_suo'" class="workImage" alt="" />
                  <video v-else :src="item" controls controlsList="nodownload" class="workImage"></video>
                </a>
            </div>

            <viewer ref="viewObj" key="work" :preload="true" :images="content">
              <div v-for="(item,index) in content" :key="index">
                <img v-if="!item.toLowerCase().includes('.mp4')" :src="item + '/applet_size'" style="display:none;" />
              </div>
            </viewer>

                  
          </div>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <Footer
      :studioName="studioName"
      :weixin="weixin"
      :phone="phone"
      :studioAddress="studioAddress"
      :banner="footBg"
      :wxQrcode="wxcode" />
    <!-- 底部 -->
  </div>
</template>
<script>
import axios from 'axios'
import api from '@/utils/api.js'
import Util from '@/utils/util.js'
import '../../../public/styles/template7.css'
import '../../../public/styles/home.css'

import Menu from '../../components/common/Menu.vue'
import Footer from '../../components/common/Footer.vue'
import { mapActions } from 'vuex'
export default {
  components: {
    Menu,
    Footer
  },
  data() {
    return {
      logoUrl:'',
      bannerList:[],
      studioName:'',
      studioDesc:'',
      studioAddress:'',
      video:'',
      phone:'',
      weixin:'',
      info:'',
      contentImg:[],
      banner:[],
      footBg:'',
      wxcode:'',

      imgSuffix:'',
      fields:{},
      param:{},
      appId:'',
      studioInfo:{},
      option:{},
      title:'',
      content:[],
      contentThumb:[],
      thumbShow:false,
      thumbLink:'',
      currentKey:0,
      viewer:{}
    }
  },
  mounted: function () {
    this.option = this.$route.query
    this.viewer = this.$refs.viewObj.$viewer
    this.viewer.init()
    this.GetStudioInfo().then(res=>{
      this.studioInfo = res
      this.appId = this.studioInfo.applet_appid
      this.param.appId = this.appId
      this.init()
      this.getInfo()
      
    })
    window.addEventListener('keydown', (event) => {
      // console.log('event', event)
      if (event.keyCode === 37) {
        // 左箭头
        this.handleShowPrev()
        
      } else if (event.keyCode === 39) {
        // 右箭头
        this.handleShowNext()
      }
      
    })

  },
  methods: {
    ...mapActions(['GetStudioInfo']),
    init(){
      this.imgSuffix = Util.getImageThumbSuffix(this.studioInfo.storageType)
    
      this.studioName = this.studioInfo.brand_name
      this.studioDesc = this.studioInfo.brand_desc
      this.studioAddress = this.studioInfo.address
      this.logoUrl = this.studioInfo.logo_url !== '' ? this.studioInfo.logo_url[0][0] : ''
      this.weixin = this.studioInfo.kefu_weixin
      this.phone = this.studioInfo.kefu_phone
      this.video = this.studioInfo.applet_video !== '' ? this.studioInfo.applet_video[0][0] : ''
      this.wxcode = this.studioInfo.corp_qrcode_url !== '' ? this.studioInfo.corp_qrcode_url[0][0] : ''
      this.footBg = this.studioInfo.banner_img !== '' ? this.studioInfo.banner_img[0][0] : ''
    },
    handleShowPrev(){
      if(this.currentKey === 0){
        this.currentKey = this.content.length - 1
      }else{
        this.currentKey--
      }
      this.thumbLink = this.content[this.currentKey] + '/applet_size'
    },
    handleShowNext(){
      if(this.currentKey === this.content.length - 1){
        this.currentKey = 0
      }else{
        this.currentKey++
      }
      this.thumbLink = this.content[this.currentKey] + '/applet_size'
    },
    handleShowOrign(){
      this.thumbShow = false
      this.viewer.view(this.currentKey)
    },
    handleChooseShow(item,index){
      this.currentKey = index
      this.thumbLink = item + '/applet_size'
    },
    handleCloseBanner(){
      this.thumbShow = false
    },
    handleShowThumb (link, index) {
      console.log('link, index', link, index)
      this.handleChooseShow(link)
      this.currentKey = index
      this.thumbShow = true
    },
    showViewPic(key){
      this.viewer.view(key)      
    },
    getInfo(){
      const that = this
			const param = {
					appId:this.studioInfo.applet_appid,
					type:3,
          id:this.option.id
				}
			axios.get(api.GET_WORKS_INFO,{
				params:param
			}).then(
				function(response) {
          const viewColumn =  response.data.data.viewColumn
          for(const index in viewColumn){
            const fieldInfo = viewColumn[index]
            if(fieldInfo.system  === 1){
              that.fields[fieldInfo.systemFields] = fieldInfo.id
            }
          }
          const info = response.data.data.data[0]

          that.title = info[that.fields.works_name]
          that.content = []
          // that.contentThumb = []
          const banner = info[that.fields.works_cover]
          banner.forEach(img=>{
            that.content.push(img[0])
          })
          const content_img = info[that.fields.content_img]
          content_img.forEach(img=>{
            that.content.push(img[0])
          })
				},
				function(res) {
					console.log(res.data)
				}
			)
    }
    

  }
}
</script>
<style>
  .workImage_wrap_item{
    margin-top: 5px;
  }

  .bannerBox{
    position: fixed;
    width:100%;
    height:100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9999;
    margin:0 auto;
  }
  .bannerBox .viewPic{
    position: absolute;
    left: 0;
    width:80%;
    height:90%;
    /* background: red; */
    margin-left: 20px;
    margin-top:20px;
    margin-bottom:20px;
    text-align: center;
  }
  .bannerBox .viewPic img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .bannerBox .viewList{
    position: absolute;
    right:0;
      width:20%;
      height:100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      overflow-y: scroll;
      align-content: flex-start;
      justify-content: center;
      overflow-y: scroll;
  }
  .bannerBox .viewList div{
    width:100px;
    height:100px;
    border-radius: 12px;
    padding:10px;
  }
  .bannerBox .viewList div img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
    cursor: pointer;
    border:4px solid #333;
    transition: border 0.5s;
  }
  .bannerBox .viewList div img:hover{
    border:4px solid #e8e8e8;
  }
  .bannerBox .viewList div img.selected{
    border:4px solid #e8e8e8;
  }
  .bannerBox .viewBtn{
    position: absolute;
    left:40%;
    bottom:10px;
    width:60px;
    height:30px;
    z-index:1000;
    /* background: #000; */
    border-radius: 12px;
    text-align: center;
    line-height: 28px;
    color:#fff;
    margin:5px;
    font-size:8px;
    border:1px solid #e8e8e8;
    cursor: pointer;
    transition: background-color 1s;
  }
  .bannerBox .viewBtn:hover{
    background: #fff;
    color:#000;
  }
  .bannerBox .viewBtn.mt-1{
    left:calc(40% + 80px);
  }
  .bannerBox .viewBtn.mt-2{
    left:calc(40% + 160px);
  }
  .bannerBox .viewBtn.mt-3{
    left:calc(40% + 240px);
  }
  </style>
  