<template>
  <div>
    <!-- 头部 -->
    <Menu :actived="0" />
    <!-- 头部 -->

    <!-- 轮播图 -->
    <Carousel :bannerList="bannerList" :mode="bannerMode"></Carousel>
    <!-- 轮播图 -->

    <div class="template7_inner_container">
      <div class="template7_cover_waterfall_wrap">

        <!-- 简介 -->
        <div class="template7_description_wrap">
          <router-link class="studioDesc" to="/about">
            <div class="description_wrap">
              <div
                class="avatar_url"
              >
                <img :src="logoUrl" class="img-fluid" alt="">
              </div>
              <div class="header_des">
                <div class="title">{{ studioName }}</div>
                <div class="sub_title">About Us</div>
                <div class="description_content">
                  <p>
                    {{ studioDesc }}
                  </p>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <!-- 简介 -->

        <!-- 案例 -->
        <div class="template7_index_waterfall_wrap">
          <div class="des_header">
            <p class="title"><span>GALLERY</span></p>
            <p class="sub_title">作品展示</p>
          </div>
        </div>
        <div class="waterfall_cover_wrap">
          
          <div
            class="waterfall_cover_box_5"
            style="
              margin: 2px;
              position: relative;
              cursor: pointer;
              display: inline-block;
              width: 299.8535871156662px;
              -webkit-flex-grow: 299.8535871156662;
              flex-grow: 299.8535871156662;
              opacity: 1;
              overflow: hidden;
            "
            v-if="worksList[0] !== undefined"
          >
            <router-link target="_blank" class="router" :to="{path:'/worksInfo',query: {id: worksList[0].id}}">
              <i style="display: block; padding-bottom: 66.69921875%"></i
              ><img
                style="
                position: absolute;
                top: 0;
                width: 100%;
                vertical-align: bottom;
              "
                class="img-fluid"
                :src="worksList[0].cover"
                alt="loading" />
            </router-link>
          </div>
          


          <div
            class="waterfall_cover_box_5"
            style="
              margin: 2px;
              position: relative;
              cursor: pointer;
              display: inline-block;
              width: 299.8535871156662px;
              -webkit-flex-grow: 299.8535871156662;
              flex-grow: 299.8535871156662;
              opacity: 1;
              overflow: hidden;
            "
            v-if="worksList[1] !== undefined"
          >
            <router-link target="_blank" class="router" :to="{path:'/worksInfo',query: {id: worksList[1].id}}">
              <i style="display: block; padding-bottom: 66.69921875%"></i
              ><img
                style="
                position: absolute;
                top: 0;
                width: 100%;
                vertical-align: bottom;
              "
                class="img-fluid"
                :src="worksList[1].cover"
                alt="loading" />
            </router-link>
          </div>

          <div
            class="waterfall_cover_box_5"
            style="
              margin: 2px;
              position: relative;
              cursor: pointer;
              display: inline-block;
              width: 299.8535871156662px;
              -webkit-flex-grow: 299.8535871156662;
              flex-grow: 299.8535871156662;
              opacity: 1;
              overflow: hidden;
            "
            v-if="worksList[2] !== undefined"
          >
            <router-link target="_blank" class="router" :to="{path:'/worksInfo',query: {id: worksList[2].id}}">
              <i style="display: block; padding-bottom: 66.69921875%"></i>
              <img
                style="
                position: absolute;
                top: 0;
                width: 100%;
                vertical-align: bottom;
              "
                class="img-fluid"
                :src="worksList[2].cover"
                alt="loading" />
            </router-link>
          </div>

          <div
            class="waterfall_cover_box_5"
            style="
              margin: 2px;
              position: relative;
              cursor: pointer;
              display: inline-block;
              width: 299.8535871156662px;
              -webkit-flex-grow: 299.8535871156662;
              flex-grow: 299.8535871156662;
              opacity: 1;
              overflow: hidden;
            "
            v-if="worksList[3] !== undefined"
          >
            <router-link target="_blank" class="router" :to="{path:'/worksInfo',query: {id: worksList[2].id}}">
              <i style="display: block; padding-bottom: 66.69921875%"></i>
              <img
                style="
                position: absolute;
                top: 0;
                width: 100%;
                vertical-align: bottom;
              "
                class="img-fluid"
                :src="worksList[3].cover"
                alt="loading" />
            </router-link>
          </div>

          <div
            class="waterfall_cover_box_5"
            style="
              margin: 2px;
              position: relative;
              cursor: pointer;
              display: inline-block;
              width: 299.8535871156662px;
              -webkit-flex-grow: 299.8535871156662;
              flex-grow: 299.8535871156662;
              opacity: 1;
              overflow: hidden;
            "
            v-if="worksList[4] !== undefined"
          >
            <router-link target="_blank" class="router" :to="{path:'/worksInfo',query: {id: worksList[4].id}}">
              <i style="display: block; padding-bottom: 66.69921875%"></i>
              <img
                style="
                position: absolute;
                top: 0;
                width: 100%;
                vertical-align: bottom;
              "
                class="img-fluid"
                :src="worksList[4].cover"
                alt="loading" />
            </router-link>
          </div>

          <div
            class="waterfall_cover_box_5"
            style="
              margin: 2px;
              position: relative;
              cursor: pointer;
              display: inline-block;
              width: 300.2932551319648px;
              -webkit-flex-grow: 300.2932551319648;
              flex-grow: 300.2932551319648;
              opacity: 1;
              overflow: hidden;
            "
            v-if="worksList[5] !== undefined"
          >
            <router-link target="_blank" class="router" :to="{path:'/worksInfo',query: {id: worksList[5].id}}">
              <i style="display: block; padding-bottom: 66.69921875%"></i>
              <img
                style="
                position: absolute;
                top: 0;
                width: 100%;
                vertical-align: bottom;
              "
                class="img-fluid"
                :src="worksList[5].cover"
                alt="loading" />
            </router-link>
          </div>

          <div
            class="waterfall_cover_box_5"
            style="
              margin: 2px;
              position: relative;
              cursor: pointer;
              display: inline-block;
              width: 299.8535871156662px;
              -webkit-flex-grow: 299.8535871156662;
              flex-grow: 299.8535871156662;
              opacity: 1;
              overflow: hidden;
            "
            v-if="worksList[6] !== undefined"
          >
            <router-link target="_blank" class="router" :to="{path:'/worksInfo',query: {id: worksList[6].id}}">
              <i style="display: block; padding-bottom: 66.69921875%"></i>
              <img
                style="
                position: absolute;
                top: 0;
                width: 100%;
                vertical-align: bottom;
              "
                class="img-fluid"
                :src="worksList[6].cover"
                alt="loading" />
            </router-link>
          </div>

          <div
            class="waterfall_cover_box_5"
            style="
              margin: 2px;
              position: relative;
              cursor: pointer;
              display: inline-block;
              width: 199.92190550566184px;
              -webkit-flex-grow: 199.92190550566184;
              flex-grow: 199.92190550566184;
              opacity: 1;
              overflow: hidden;
            "
            v-if="worksList[7] !== undefined"
          >
            <router-link target="_blank" class="router" :to="{path:'/worksInfo',query: {id: worksList[7].id}}">
              <i
                style="
              display: block;
              padding-bottom: 100.03906250000001%;
            "></i>
              <img
                style="
                position: absolute;
                top: 0;
                width: 100%;
                vertical-align: bottom;
              "
                class="img-fluid"
                :src="worksList[7].cover"
                alt="loading" />
            </router-link>
          </div>

          <div
            class="waterfall_cover_box_5"
            style="
              margin: 2px;
              position: relative;
              cursor: pointer;
              display: inline-block;
              width: 300.5135730007337px;
              -webkit-flex-grow: 300.5135730007337;
              flex-grow: 300.5135730007337;
              opacity: 1;
              overflow: hidden;
            "
            v-if="worksList[8] !== undefined"
          >
            <router-link target="_blank" class="router" :to="{path:'/worksInfo',query: {id: worksList[8].id}}">
              <i style="display: block; padding-bottom: 66.69921875%"></i>
              <img
                style="
                position: absolute;
                top: 0;
                width: 100%;
                vertical-align: bottom;
              "
                class="img-fluid"
                :src="worksList[8].cover"
                alt="loading" />
            </router-link>
          </div>

          <div
            class="waterfall_cover_box_5"
            style="
              margin: 2px;
              position: relative;
              cursor: pointer;
              display: inline-block;
              width: 200px;
              -webkit-flex-grow: 200;
              flex-grow: 200;
              opacity: 1;
              overflow: hidden;
            "
            v-if="worksList[9] !== undefined"
          >
            <router-link target="_blank" class="router" :to="{path:'/worksInfo',query: {id: worksList[9].id}}">
              <i style="display: block; padding-bottom: 100%"></i>
              <img
                style="
                position: absolute;
                top: 0;
                width: 100%;
                vertical-align: bottom;
              "
                class="img-fluid"
                :src="worksList[9].cover"
                alt="loading" />
            </router-link>
          </div>  

          

          
          
          
        </div>

        <router-link class="more_btn2" to="/worksList">MORE</router-link>
      </div>
      <!-- 案例 -->

      <!-- 视频 -->
      <div class="template7_index_waterfall_wrap template8_video_index_container" v-if="video">
        <div class="des_header">
          <p class="title"><span>VIDEO</span></p>
          <p class="sub_title">视频展示</p>
        </div>

        <div class="video_wrap" style="overflow:hidden;background:#000;margin-top:3em;">
          <div class="video_is_pfop" style="height:600px">
            <video
              :src="video"
              style="width:100%;height:600px;"
              controls
              controlsList="nodownload"
              autoplay
              loop></video>
          </div>
        </div>
      </div>
      <!-- 视频 -->

      <!-- 服务套餐 -->
      <div class="template7_index_waterfall_wrap">
        <div class="des_header">
          <p class="title"><span>SERVICE</span></p>
          <p class="sub_title">服务套餐</p>
        </div>

        <div class="work_list_wrap fadeIn animation2" style="margin-top:3em;">
          <div class="work_list_content" style="transform: translateX(0)">

            <div
              class="work_item_wrap"
              v-for="item in goodsList"
              :key="item.id"
            >

            
              <div class="work_item">
                <a>
                  <div style="width: 100%;overflow: hidden">
                    <div class="cover_url_item">
                      <router-link target="_blank" class="router" :to="{path:'/goodsInfo',query: {id: item.id}}">
                        <img :src="item.cover" class="img-fluid" alt=""/>
                      </router-link>
                    </div>
                  </div>
                  <div class="title ellipsis">
                    {{ item.name }}
                  </div>
                  <div class="sub_title">{{ item.price }}</div>
                </a>
                <p class="live_tag_wrap">
                  <i class="icon-video05"></i
                  ><span><span>热门套餐</span></span>
                </p>
              </div>

            </div>

            
          </div>
        </div>

        <div class="">
          <router-link class="more_btn" to="/goodsList">MORE</router-link>
        </div>
        
      </div>
      <!-- 服务套餐 -->

      <!-- 底部 -->
      <Footer
        :studioName="studioName"
        :weixin="weixin"
        :phone="phone"
        :studioAddress="studioAddress"
        :banner="footBg"
        :wxQrcode="wxcode"
      />
      <!-- 底部 -->


    </div>
  </div>
</template>
<script>
import axios from 'axios'
import api from '@/utils/api.js'
import Util from '@/utils/util.js'
import '../../public/styles/template7.css'
import '../../public/styles/home.css'
import Footer from '../components/common/Footer'
import Carousel from '../components/common/Carousel'
import Menu from '../components/common/Menu'
export default {
	name:'Home',
	components:{
		Footer,
		Menu,
		Carousel
	},
	data() {
		return {
			studioInfo:{},
			logoUrl:'',
			bannerList:[],
			studioName:'',
			studioDesc:'',
			studioAddress:'',
			goodsList:[],
			video:'',
			weixin: '',
			phone: '',
			worksList:[],
			codeShow:0,
			menuInfo:[],
			autoplay:true,
			footBg:'',
			wxcode:'',
			bannerMode:0,
			imgSuffix:''
		}
	},
	mounted: function () {
		this.studioInfo = this.$store.state.info
		this.init()
	},
	methods: {
		init(){
			this.imgSuffix = Util.getImageThumbSuffix(this.studioInfo.storageType)
			this.getBannerList()
			this.studioName = this.studioInfo.brand_name
      this.studioDesc = this.studioInfo.brand_desc
			this.studioAddress = this.studioInfo.address
			this.weixin = this.studioInfo.kefu_weixin
			this.phone = this.studioInfo.kefu_phone
			this.bannerMode = Number(this.studioInfo.banner_type)
      
      try {
        this.logoUrl = this.studioInfo.logo_url[0][0]
      } catch (error) {
        console.log(error);
      }
      try {
        this.video = this.studioInfo.applet_video[0][0]
      } catch (error) {
        console.log(error);
      }
      try {
        this.wxcode = this.studioInfo.corp_qrcode_url[0][0]
      } catch (error) {
        console.log(error);
      }
      try {
        this.footBg = this.studioInfo.banner_img[0][0]
      } catch (error) {
        console.log(error);
      }
			this.getWorksList()
			this.getGoodsList()
		},
		getBannerList(){
			this.bannerList = []
			this.studioInfo.banner_img.forEach(item=>{
				this.bannerList.push(item[0] + this.imgSuffix)
			})
		},
		getWorksList(){
      const that = this
			const param = {
					appId:this.studioInfo.applet_appid,
					type:3,
					pageNo:1,
					pageSize:10
				}
			axios.get(api.GET_WORKS_LIST,{
				params:param
			}).then(
				function(response) {
					that.worksList = []
					response.data.data.forEach(item=>{
						if(item['works_cover'] != null && item['works_cover'] != ''){
							that.worksList.push({
								id:item.id,
								cover:item['works_cover'][0][0] + that.imgSuffix
							})
						}
					})
				},
				function(res) {
					console.log(res.data)
				}
			)
		},
		getGoodsList(){
      const that = this
			const param = {
					appId:this.studioInfo.applet_appid,
					type:1,
					pageNo:1,
					pageSize:9
				}
			axios.get(api.GET_GOODS_LIST,{
				params:param
			}).then(
				function(response) {
					that.goodsList = []
					response.data.data.forEach(item=>{
						if(item['goods_cover'] != null && item['goods_cover'] != ''){
							that.goodsList.push({
								id:item.id,
								cover:item['goods_cover'][0][0] + that.imgSuffix,
                name:item.goods_name,
                price:item.sell_price
							})
						}
					})
				},
				function(res) {
					console.log(res.data)
				}
			)
		}
	}
}
</script>
<style scoped>
#carousel-fade{
  width: 100%;
  height: 46.875vw;
  position: fixed;
  overflow: hidden;
  margin-top: 70px;
}
.nav-a{
  text-decoration: none;
}
.studioDesc{
  text-decoration: none;
}
.more_btn{
  text-decoration: none;
}
.more_btn2{
    width: 218px;
    height: 44px;
    line-height: 44px;
    font-size: 15px;
    color: #bdbdbd;
    margin: 0 auto 60px;
    text-align: center;
    border: 1px solid #dfdfdf;
    display: block;
    transition: all 0.3s;
    text-decoration: none;
}
.more_btn2:hover{
    color: #fff;
    background: rgba(130, 200, 174, .8);
}
.waterfallCss1{
  margin: 2px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 299.8535871156662px;
  -webkit-flex-grow: 299.8535871156662;
  flex-grow: 299.8535871156662;
  opacity: 1;
  overflow: hidden;
}
.waterImgCss1{
  position: absolute;
  top: 0;
  width: 100%;
  vertical-align: bottom;
}
.waterfallCss2{
  margin: 2px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 200px;
  -webkit-flex-grow: 200;
  flex-grow: 200;
  opacity: 1;
  overflow: hidden;        
}
</style>
