<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  created(){
    this.$nextTick(() => {
      // 禁用右键
      document.oncontextmenu = new Function("event.returnValue=false");
      // 禁用选择
      document.onselectstart = new Function("event.returnValue=false");
      // 如果按下键F12,阻止事件
      // document.addEventListener("keydown", function (e) {
      //   if (e.key == "F12") {
      //     e.preventDefault(); 
      //   }
      // });
      document.onkeydown = function (event) {
        //禁止ctrl+s
        if (event.ctrlKey && window.event.keyCode == 83) {
          return false;
        }
        //禁止ctrl+u
        if (event.ctrlKey && window.event.keyCode == 85) {
          return false;
        }
      }
    });
  }
}
</script>

<style>
a{
  text-decoration: none;
}
</style>
