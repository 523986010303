<template>
  <div id="index" class="template7_container">
    <!-- 头部 -->
    <Menu :actived="2" />
    <!-- 头部 -->

    <div class="template7_blog_sitecontent">

      <div class="series_list_header_wrap">
      
        <div class="header_wrap" v-if="classList.length > 0">
          <div class="classItemBox">
            <div class="" @click="tabSel(item)" :key="key" v-for="(item,key) in classList">
              <div :class="currentIndex === key ? 'classItem actived' : 'classItem '">
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="classLabelBox" v-if="showLabel">
            <div class="labelItem" @click="showLabelChild(0)">
              不限
            </div>
            <div
              @click="showLabelChild(item.id)"
              :class="currentLabel === item.id ? 'labelItem actived' : 'labelItem '"
              :key="index"
              v-for="(item,index) in labelList">
              {{ item.name }}
            </div>
          </div>
          <div class="classLabelBox" v-if="showChild">
            <div class="labelItem" @click="reloadDataList(0)">
              不限
            </div>
            <div
              :class="currentChildLabel === item.id ? 'labelItem actived' : 'labelItem'"
              @click="reloadDataList(item.id)"
              :key="index"
              v-for="(item,index) in labelchild">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>

      <div>
        <div>
          <div
            class="workListWrapTemplate workListWrapTemplate2"
            style="padding-bottom:20px;"
            v-for="item in dataList"
            :key="item.id">
          
            <router-link target="_blank" class="router" :to="{path:'/goodsInfo',query: {id: item.id}}">
              <div style="overflow: hidden;">
                <a class="workImage_wrap">
                  <img :src="item.cover" class="workImage img-fluid" alt="" />
                  <p class="live_tag_wrap"><i class="icon-video05"></i><span><span>热销套系</span></span>
                  </p>
                </a>
              </div>
            </router-link>
            <div class="workContentWrap">
              <div class="workTile workTile_5" style="color:#fff;margin-top:20px;"><a>{{ item.title }}</a></div>
              <!-- <div class="workCreatedAt">October 16, 2022</div>
                              <div class="work_title_border"></div> -->
              <pre class="workContent workContent_5" style="font-size:20px;margin:0;">价格：{{ item.price }}</pre>
            </div>
            <!-- <div class="workOperateWrap flexBetween"><span></span>
                                <div>
                                    <div class="workShareList workShareList_5"><i class="icon-share01 onShareBtn"></i><span><span>分享</span></span></div>
                                    <div class="workMoreList workMoreList_5"><a><i class="icon-more01 onShareBtn"></i><span>更多</span></a></div>
                                </div>
                            </div> -->
          </div>
          
        </div>
        <div class="more_btn2" v-if="dataList.length === 0">暂无相关内容</div>
        <div class="more_btn2" @click="nextLoadList" v-if="dataList.length > 0">{{ nextTxt }}</div>
      </div>
    </div>



    <!-- 底部 -->
    <Footer
      :studioName="studioName"
      :weixin="weixin"
      :phone="phone"
      :studioAddress="studioAddress"
      :banner="footBg"
      :wxQrcode="wxcode" />
    <!-- 底部 -->

  </div>
</template>
<script>
import axios from 'axios'
import api from '@/utils/api.js'
import Util from '@/utils/util.js'
import '../../../public/styles/template7.css'
import '../../../public/styles/home.css'
import Menu from '@/components/common/Menu.vue'
import Footer from '@/components/common/Footer.vue'
import { mapActions } from 'vuex'
export default {
  components: {
    Menu,
    Footer
  },
  data () {
    return {
      logoUrl: '',
      bannerList: [],
      studioName: '',
      studioDesc: '',
      studioAddress: '',
      video: '',
      phone: '',
      weixin: '',
      wxcode: '',
      footBg: '',
      themeType: 0,
      //分类
      tablist: [],
      hasCurKey: false,
      showChild: false,
      showLabel: 0,
      labelList: [],
      studioId: 0,
      param: {
        pageNo: 1,
        pageSize: 12,
        appId:'',
        type:1
      },
      classParam: {},
      nextTxt: '点击加载更多',
      appId:'',
      fields:{},
      classMap:{},
      classList:[],
      option:{},
      currentIndex:0,
      currentChildLabel:0,
      currentLabel:0,
      dataList:[]
    }
  },
  mounted: function () {
    this.option = this.$route.query
  
    this.GetStudioInfo().then(res=>{
      this.studioInfo = res
      this.appId = this.studioInfo.applet_appid
      this.param.appId = this.appId
      this.init()
      this.getTableFields(1).then(res => {
        this.param['#'+res.goods_category] = this.option.class != undefined ? this.option.class : ''
      })
      // 加载分类与标签
      this.getClass()
    })

  },
  methods: {
    ...mapActions(['GetStudioInfo']),
    init(){
      this.imgSuffix = Util.getImageThumbSuffix(this.studioInfo.storageType)
      
      this.studioName = this.studioInfo.brand_name
      this.studioDesc = this.studioInfo.brand_desc
      this.studioAddress = this.studioInfo.address
      this.weixin = this.studioInfo.kefu_weixin
      this.phone = this.studioInfo.kefu_phone
      this.video = this.studioInfo.applet_video !== '' ? this.studioInfo.applet_video[0][0] : ''

      try {
        this.logoUrl = this.studioInfo.logo_url[0][0]
        this.footBg = this.studioInfo.banner_img[0][0]
        this.wxcode = this.studioInfo.corp_qrcode_url[0][0]
      } catch (error) {
        console.log(error);
      }
    },
    getTableFields(typeId){
      return new Promise((next) => {
        const param = {
          appId: this.appId,
          type: typeId
        }
        this.fields[typeId] = {}
        const that = this
        axios.get(api.GET_TABLE_FIELDS,{
          params:param
        }).then(res=>{
          for (const key in res.data.data) {
            that.fields[typeId][res.data.data[key]] = key
          }
          next(that.fields[typeId])
        })
      })
      
    },

    getClass () {
      const that = this
      const param = {
        appId: this.appId,
        type: 1,
        pageNo: 1,
        pageSize: 50
      }
      axios.get(api.GET_CLASS_LIST,{
        params:param
      }).then(
        function(response) {
          const fields = {}
          response.data.data.fields.forEach(item => {
            if (item.system == 1) {
              fields[item.systemFields] = item.dataIndex
            }
          })
          that.classMap = {}
          that.classList = [{
            id: 0,
            name: '全部',
            index:0
          }]
          that.classMap[0] = 0
          if (response.data.data.data.length != 0) {
            
            response.data.data.data.forEach((item, key) => {
              const classItem = {
                id: item.id,
                name: item[fields['server_name']],
                index:key + 1
              }
              that.classMap[classItem.id] = key + 1
              that.classList.push(classItem)
            })
          }
          if (that.option.class) {
            that.currentIndex = that.classMap[that.option.class] !== undefined ? that.classMap[that.option.class] : 0
          } else {
            that.currentIndex = 0
          }
          var currentTab = that.classList[that.currentIndex]
          that.tabSel(currentTab)
        },
        function(res) {
          console.log(res.data)
        }
      )
    },
    filterParam () {
      const param = {}
      if (this.param['#' + this.fields[1].goods_category] !== undefined) {
        param.class = this.param['#' + this.fields[1].goods_category]
      }
      // if (this.param['#' + this.fields[3].works_lable] !== undefined) {
      //   param.label = this.param['#' + this.fields[3].works_lable]
      // }
      param.appId = this.param.appId
      param.type = this.param.type
      param.pageNo = this.param.pageNo
      param.pageSize = this.param.pageSize

      return param
    },
    getDataList () {
      const that = this
      const param = this.filterParam()
      axios.get(api.GET_GOODS_LIST, {
        params: param
      }).then(
        function (response) {
          console.log(response)
          response.data.data.forEach(item => {
            if (item['goods_cover'] != null && item['goods_cover'] != '') {
              that.dataList.push({
                id: item.id,
                title:item.goods_name,
                cover:item['goods_cover'][0][0] + that.imgSuffix,
                price:item.sell_price
              })
            }
          })

          if (response.data.data.length < that.param.pageSize) {
            that.nextTxt = '没有更多内容了'
          } else {
            that.nextTxt = '点击加载更多'
          }

        },
        function (res) {
          console.log(res.data)
        }
      )
    },
    tabSel (item) {

      if (item.id != this.option.class){
          this.option = {}
          this.currentChildLabel = 0
          this.currentLabel = 0
          this.param['#' + this.fields[1].goods_category] = 0
          // this.param['#' + this.fields[1].works_lable] = 0
        }
        this.currentIndex = item.index
        this.param.pageNo = 1
        this.param['#' +this.fields[1].goods_category] = item.id
        // this.showLabelList(item.id)
        this.reloadList()
    },
    reloadDataList (label) {
      
      this.param.pageNo = 1
      this.currentChildLabel = label
      // this.param['#' + this.fields[1].works_lable] = label
      this.reloadList()
    },
    reloadList () {
      this.dataList = []
      this.getDataList()
    },
    nextLoadList () {

      this.param.pageNo = Number(this.param.pageNo) + 1
      this.getDataList()
    },
    showLabelList () {
      this.showChild = false
      this.showLabel = 0
      this.currentLabel = 0
      this.currentChildLabel = 0
      // if (class_id > 0) {
      //   this.getLabel(class_id)
      //   this.showLabel = true
      // } else {
      //   this.param['#' + this.fields[1].works_lable] = 0
      // }

    },
    getLabel (class_id) {
      this.classParam.appId = this.appId
      this.classParam.type = 6
      this.classParam['#'+this.fields[1].class_id] = class_id
      this.classParam.pageNo = 1
      this.classParam.pageSize = 50
      this.labelList = []
      this.labelchild = []
      // 隐藏底部
      this.hunyue = 0

      axios.get(api.GET_LABEL_LIST, {
        params: this.classParam
      }).then(res => {
        const labelResult = res.data.data
        const fields = {}
        labelResult.fields.forEach(item => {
          if (item.system == 1) {
            fields[item.systemFields] = item.dataIndex
          }
        })
        const demoLabel = []
        this.labelMap = {}
        if (labelResult.data.length != 0) {
          const sourceData = labelResult.dataSet
          sourceData.forEach(item => {
            const labelItem = {
              id: item.id,
              name: item[fields['label_name']],
              class_id: item[fields['class_id']],
              parent_id: item[fields['parent_id']] ? item[fields['parent_id']] : 0
            }
            this.labelMap[labelItem.id] = labelItem.parent_id
            
            demoLabel.push(labelItem)
          })
          const labelData = Util.getLabelTree(demoLabel)
          if (labelData.length == 1){
            this.showLabel = false
            this.showChild = true
            this.labelchild = labelData[0].children
          }else{
            this.showLabel = true
            this.showChild = false
          }
          this.labelList = labelData
          if (this.currentChildLabel == 0 && this.option.label != undefined){
            this.currentChildLabel = this.option.label != undefined ? this.option.label : 0
            this.currentLabel = this.labelMap[this.currentChildLabel]
            this.labelList.forEach((label,key)=>{
              if (label.id == this.currentLabel){
                this.labelchild = labelData[key].children
              }
            })
            this.showChild = true
          }
        }else{
          this.showLabel = 0
          this.showChild = false
        }
        setTimeout(() => {
          this.hunyue = 1
        }, 1000)

      }, err => {
        console.log(err)
      })

    },
    showLabelChild (pid) {
      this.currentLabel = pid
      
      let demoArr = []
      this.labelList.forEach(item => {
        if (item.id == pid) {
          demoArr = item.children
        }
      })
      this.labelchild = demoArr
      this.showChild = pid !== 0
    }
  }
}
</script>
<style>
#carousel-fade {
  width: 100%;
  height: 46.875vw;
  position: fixed;
  overflow: hidden;
  margin-top: 70px;
}

.nav-a {
  text-decoration: none;
}

.studioDesc {
  text-decoration: none;
}
.workListWrapTemplate{
  max-width: 1200px;
}
</style>
  