
function sub(str,n){
  var r = '/[^\x00-\xff]/g'
  if(str.replace(r,'mm').length<=n){return str}
  var m=Math.floor(n/2)
  for(var i=m;i<str.length;i++){
      if(str.substr(0,i).replace(r,'mm').length>=n){
          return str.substr(0,i)+'...'
      }
  }
  return str
}


function getImageThumbSuffix(storageType = 0){
	if(storageType == 0 || storageType == 1 || storageType == undefined){
		return '?1=1&x-oss-process=style'
	}else if(storageType == 2){
		return '/applet_suo'
	}
}
function getImageLink(imgLink,thumbSuffix){
	if(imgLink == '' || imgLink == undefined || imgLink == null){
		return ''
	}
	if(imgLink[0] == undefined){
		return ''
	}
	if(imgLink[0][0] != ''){
		return imgLink[0][0] + thumbSuffix
	}
	return ''
}

function getLabelTree(sourceData = [], pid = 0){
	if(sourceData.length  === 0){
		return []
	}
	const result = []
	sourceData.forEach(item=>{
		if(item.parent_id === pid){
			item.children = getLabelTree(sourceData,item.id)
			result.push(item)
		}
	})
	return result
}

 //百度地图坐标转腾讯、高德地图坐标
function transPosition(bd_lng, bd_lat) {
     var X_PI = Math.PI * 3000.0 / 180.0
     var x = bd_lng - 0.0065
     var y = bd_lat - 0.006
     var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI)
     var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI)
     var gg_lng = z * Math.cos(theta)
     var gg_lat = z * Math.sin(theta)
     return {lon: gg_lng, lat: gg_lat}
 }

module.exports = {
	getImageThumbSuffix,
	getImageLink,
	getLabelTree,
	transPosition,
	sub
}
