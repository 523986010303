<template>
  <div id="index" class="template7_container">
    <!-- 头部 -->
    <Menu :actived="3" />
    <!-- 头部 -->

    <div class="template7_blog_sitecontent">
      <div class="series_list_header_wrap">

        <div class="header_wrap" v-if="classList.length > 0">
          <div class="classItemBox">
            <div class="" @click="showChildClassList(item.id)" :key="key" v-for="(item,key) in classList" >
              <div :class="currentIndex === item.id ? 'classItem actived' : 'classItem '">
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="classItemBox" v-if="showChildClass">
            <div class="" @click="showLabelChild(0)">
              <div :class="currentLabel === 0 ? 'classItem actived' : 'classItem '">
                不限
              </div>
            </div>
            <div class="" @click="showLabelChild(item.id)" :key="key" v-for="(item,key) in labelList" >
              <div :class="currentLabel === item.id ? 'classItem actived' : 'classItem '">
                {{ item.name }}
              </div>
            </div>
          </div>

          <!-- <div class="classLabelBox" v-if="showChildClass">
            <div class="labelItem" @click="showLabelChild(0)">
              不限
            </div>
            <div
              @click="showLabelChild(item.id)"
              :class="currentLabel === item.id ? 'labelItem actived' : 'labelItem '"
              :key="index"
              v-for="(item,index) in labelList">
              {{ item.name }}
            </div>
          </div> -->


          <div class="classLabelBox trueLabel" v-if="showLabel">
            <div class="labelItem" @click="reloadDataList(0)">
              不限
            </div>
            <div
              :class="currentChildLabel === item.id ? 'labelItem actived' : 'labelItem'"
              @click="reloadDataList(item.id)"
              :key="index"
              v-for="(item,index) in labelchild">
              {{ item.name }}
            </div>
          </div>
        </div>

        <div class="maxWrap overflow seriesWrap1 seriesWrap" style="max-width: 100%;">

          <div class="seriesItemWrap" v-for="item in dataList" :key="item.id">
            <router-link target="_blank" class="router" :style="{display:'block',overflow:'hidden',height:autoHeight}" :to="{path:'/caseInfo',query: {id: item.id}}">
              <img :class="'img-fluid seriesItem themeCss' + themeType " :src="item.cover" alt="">
            </router-link>
            <!-- <img class="seriesItem" src="" alt=""> -->
            <div class="seriesItemName_wrap verticalAlign">
              <div class="seriesItemName seriesItemName_5 ellipsis">{{ item.title }}
              </div>
            </div>
            <!-- <p class="series_detail_info"><span> </span><span>1小时</span><span>拍摄</span>\<span>拍摄</span>100<span>张</span>\20<span>张精修全送</span></p> -->
            <router-link target="_blank" class="router" :to="{path:'/caseInfo',query: {id: item.id}}">
              <p class="seriesItemPrice seriesItemPrice_5" style="font-size:13px"><span>点击查看</span></p>
            </router-link>
          </div>
                
        </div>
        <div class="more_btn2" v-if="dataList.length === 0">暂无相关内容</div>
        <div class="more_btn2" @click="nextLoadList" v-if="dataList.length > 0">{{ nextTxt }}</div>
      </div>
    </div>


    

    <!-- 底部 -->
    <Footer
      :studioName="studioName"
      :weixin="weixin"
      :phone="phone"
      :studioAddress="studioAddress"
      :banner="footBg"
      :wxQrcode="wxcode" />
    <!-- 底部 -->

  </div>
</template>
<script>
import axios from 'axios'
import api from '@/utils/api.js'
import Util from '@/utils/util.js'
import '../../../public/styles/template7.css'
import '../../../public/styles/home.css'
import Menu from '../../components/common/Menu.vue'
import Footer from '../../components/common/Footer.vue'
import { mapActions } from 'vuex'
export default {
components: {
  Menu,
  Footer
},
data() {
  return {
    logoUrl:'',
    bannerList:[],
    studioName:'',
    studioDesc:'',
    studioAddress:'',
    video: '',
    phone: '',
    weixin:'',
    footBg:'',
    wxcode:'',
    themeType:0,
    //分类
    tablist: [],
    hasCurKey: false,
    showLabel: false,
    showChildClass: 0,
    labelList: [],
    studioId: 0,
    param: {
      pageNo: 1,
      pageSize: 12,
      appId: 0,
      type:2
    },
    classParam: {},
    nextTxt: '点击加载更多',
    appId:'',
    fields:{},
    classMap:{},
    classList:[],
    childClassMap:{},
    classChildIdsMap: {},
    option:{},
    currentIndex:0,
    currentChildLabel:0,
    currentLabel:0,
    dataList:[],
    parentClassId: 0,
    autoHeight: parseInt(window.innerWidth / 4) + 'px'
  }
},
mounted: function () {
  this.option = this.$route.query
  
  this.GetStudioInfo().then(res=>{
    this.studioInfo = res
    this.appId = this.studioInfo.applet_appid
    this.param.appId = this.appId
    this.init()
    const that = this
    this.getTableFields(2).then(res => {
      this.param['#'+res.works_category] = this.option.class != undefined ? this.option.class : ''
      this.param['#'+res.works_lable] = this.option.label != undefined ? this.option.label : ''
    })
    // 加载分类与标签
    this.getTableFields(4).then(function(){
      return that.getClass()
    }).then(()=>{
      return that.getLabel()
    })
  })
  
},
methods: {
  ...mapActions(['GetStudioInfo']),
  init(){
    this.imgSuffix = Util.getImageThumbSuffix(this.studioInfo.storageType)
    
    this.studioName = this.studioInfo.brand_name
    this.studioDesc = this.studioInfo.brand_desc
    this.studioAddress = this.studioInfo.address
    this.weixin = this.studioInfo.kefu_weixin
    this.phone = this.studioInfo.kefu_phone
    this.video = this.studioInfo.applet_video !== '' ? this.studioInfo.applet_video[0][0] : ''
    try {
      this.logoUrl = this.studioInfo.logo_url[0][0]
      this.footBg = this.studioInfo.banner_img[0][0]
      this.wxcode = this.studioInfo.corp_qrcode_url[0][0]
    } catch (error) {
      console.log(error);
    }

    const that = this
    window.onresize = function () {
      that.autoHeight = parseInt(window.innerWidth / 4)+'px'
    }
  },
  getTableFields(typeId){
    return new Promise((next) => {
      const param = {
        appId: this.appId,
        type: typeId
      }
      this.fields[typeId] = {}
      const that = this
      axios.get(api.GET_TABLE_FIELDS,{
        params:param
      }).then(res=>{
        for (const key in res.data.data) {
          that.fields[typeId][res.data.data[key]] = key
        }
        next(that.fields[typeId])
      })
    })
    
  },
  setParam(searchParam = {}){
     this.param = {
      appId: this.appId,
      type: 2,
      pageNo: 1,
      pageSize: 9
    }
    this.param = {...this.param,...searchParam}
  },
  getClass () {
    return new Promise((resolve)=>{
      const that = this
      const param = {
        appId: this.appId,
        type: 2,
        pageNo: 1,
        pageSize: 50
      }
      axios.get(api.GET_CLASS_LIST,{
        params:param
      }).then(
        function(response) {
          const fields = {}
          response.data.data.fields.forEach(item => {
            if (item.system == 1) {
              fields[item.systemFields] = item.dataIndex
            }
          })
          that.classMap = {}
          that.classList = [{
            id: 0,
            name: '全部',
            sort:0
          }]
          that.classMap[0] = 0
          if (response.data.data.data.length != 0) {
            let startKey = 1
            response.data.data.dataSet.forEach((item) => {
              if(item[fields.parent_id] === 0){
                const classItem = {
                  id: item.id,
                  name: item[fields['class_name']],
                  sort: item[fields.sort],
                  key: startKey
                }
                that.classMap[classItem.id] = startKey
                that.classList.push(classItem)
                startKey++
              }else{
                if(that.childClassMap[item[fields.parent_id]] === undefined){
                  that.childClassMap[item[fields.parent_id]] = []
                  that.classChildIdsMap[item[fields.parent_id]] = []
                }
                that.childClassMap[item[fields.parent_id]].push({
                  id: item.id,
                  name: item[fields['class_name']],
                  sort: item[fields.sort]
                })
                that.classChildIdsMap[item[fields.parent_id]].push(item.id)

                that.childClassMap[item[fields.parent_id]].sort(function(a,b){
                  if(a.sort === b.sort){
                    return a.id - b.id
                  }else{
                    return a.sort - b.sort
                  }
                })
              }
            })
            // 排序
            that.classList.sort(function(a,b){
              if(a.sort === b.sort){
                return a.id - b.id
              }else{
                return a.sort - b.sort
              }
            })
          }
          
          if (that.option.class) {
            that.currentIndex = that.option.class!== undefined ? that.option.class : 0
          } else {
            that.currentIndex = 0
          }
          that.showChildClassList(that.currentIndex)
          // var currentTab = that.classList[that.currentIndex]
          // that.tabSel(currentTab)
          resolve()
        },
        function(res) {
          console.log(res.data)
        }
      )
    })
    
  },
  filterParam () {
    const param = {}
    if (this.param['#' + this.fields[2].works_category] !== undefined) {
      param.class = this.param['#' + this.fields[2].works_category]
    }
    if (this.param['#' + this.fields[2].works_lable] !== undefined) {
      param.label = this.param['#' + this.fields[2].works_lable]
    }
    param.appId = this.param.appId
    param.type = this.param.type
    param.pageNo = this.param.pageNo
    param.pageSize = this.param.pageSize

    return param
  },
  getDataList () {
    const that = this
    const param = this.filterParam()
    axios.get(api.GET_WORKS_LIST, {
      params: param
    }).then(
      function (response) {
        console.log(response)
        response.data.data.forEach(item => {
          if (item['works_cover'] != null && item['works_cover'] != '') {
            that.dataList.push({
              id: item.id,
              title:item.works_name,
              cover: item['works_cover'][0][0] + that.imgSuffix
            })
          }
        })

        if (response.data.data.length < that.param.pageSize) {
          that.nextTxt = '没有更多内容了'
        } else {
          that.nextTxt = '点击加载更多'
        }

      },
      function (res) {
        console.log(res.data)
      }
    )
  },
  showChildClassList(classId){
    this.param = {}

    this.currentIndex = classId
    console.log('classId', classId)
    if(classId === 0){
      this.parentClassId = 0
      this.showChildClass = false
      this.showLabel = false
      // 请求全部数据
      this.setParam()
      this.reloadList()
      return false
    }
    // console.log('this.childClassMap[classItem.id]', this.childClassMap[classItem.id])
    if(this.childClassMap[classId] !== undefined){
      // 存在子集分类，先拉取子集分类
      this.labelList = this.childClassMap[classId]
      this.showChildClass = true
      this.parentClassId = classId
      this.labelchild = []
      this.showLabel = false
      const searchParam = {}
      searchParam['#' + this.fields[2].works_category] = this.classChildIdsMap[classId].join(',')
      this.setParam(searchParam)
      this.reloadList()
    }else{
      this.parentClassId = 0
      // 直接读取该分类下的作品与标签
      // 组成分类查询条件
      const searchParam = {}
      searchParam['#' + this.fields[2].works_category] = classId
      // delete this.param['#' + this.fields[3].works_lable]
      // searchParam['#' + this.fields[3].works_lable] = 0
      this.setParam(searchParam)
      this.showChildClass = false
      this.reloadList()
      console.log('this.labelMap[classId]', classId , this.labelMap[classId])
      if(this.labelMap[classId] !== undefined){
        this.labelchild = this.labelMap[classId]
        this.showLabel = true
      }else{
        this.labelchild = []
        this.showLabel = false
      }
    }
  },
  reloadDataList (label) {
    
    this.param.pageNo = 1
    this.currentChildLabel = label
    this.param['#' + this.fields[2].works_lable] = label
    this.reloadList()
  },
  reloadList () {
    this.dataList = []
    this.getDataList()
  },
  nextLoadList () {

    this.param.pageNo = Number(this.param.pageNo) + 1
    this.getDataList()
  },
  getLabel () {
    this.classParam.appId = this.appId
    this.classParam.type = 4
    this.classParam.pageNo = 1
    this.classParam.pageSize = 50
    this.labelList = []
    this.labelchild = []
    // 隐藏底部
    this.hunyue = 0

    axios.get(api.GET_LABEL_LIST, {
      params: this.classParam
    }).then(res => {
      const labelResult = res.data.data
      const fields = {}
      labelResult.fields.forEach(item => {
        if (item.system == 1) {
          fields[item.systemFields] = item.dataIndex
        }
      })
      this.labelMap = {}
      const sourceData = labelResult.dataSet
        sourceData.forEach(item => {
          const labelItem = {
            id: item.id,
            name: item[fields['label_name']],
            class_id: item[fields['class_id']],
            parent_id: item[fields['parent_id']] ? item[fields['parent_id']] : 0
          }
          if(this.labelMap[labelItem.class_id] === undefined){
            this.labelMap[labelItem.class_id] = []
          }
          this.labelMap[labelItem.class_id].push(labelItem)
          
        })

    }, err => {
      console.log(err)
    })

  },
  showLabelChild (childClassId) {
    this.currentLabel = childClassId
    
    const searchParam = {}
    searchParam['#' + this.fields[2].works_category] = childClassId
    if(childClassId === 0){
      delete searchParam['#' + this.fields[2].works_category]
    }
    // delete this.param['#' + this.fields[3].works_lable]
    // searchParam['#' + this.fields[3].works_lable] = 0
    this.setParam(searchParam)
    // this.showChildClass = false
    this.reloadList()

    if(this.labelMap[childClassId] !== undefined){
      this.labelchild = this.labelMap[childClassId]
      this.showLabel = true
    }else{
      this.labelchild = []
      this.showLabel = false
    }
    
    // let demoArr = []
    // this.labelList.forEach(item => {
    //   if (item.id == pid) {
    //     demoArr = item.children
    //   }
    // })
    // this.labelchild = demoArr
    // this.showLabel = pid !== 0
  }


}
}
</script>
<style>
#carousel-fade{
  width: 100%;
  height: 46.875vw;
  position: fixed;
  overflow: hidden;
  margin-top: 70px;
}
.nav-a{
  text-decoration: none;
}
.router{
  text-decoration: none;
}
  
</style>
