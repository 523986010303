<template>
  <div id="index" class="template7_container">
    <!-- 头部 -->
    <Menu :actived="4" />
    <!-- 头部 -->

    <div class="template7_about_sitecontent">
      <div class="maxWrap center about_container">
        <div class="photographerAvatar photographerAvatar_5" >
          <img :src="logoUrl" style="width:100%;" />
        </div>
        <div class="center photographerName photographerName_5" style="font-size:20px;">{{ studioName }}</div>
        <!-- <div class="center photographerLocation" style="color:#CFBB90">记录时光中的最美瞬间</div> -->
        <div class="center photographerLocation">微信 {{ weixin }}</div>
        <div>
          <div class="aboutDescription center aboutDescription_5" style="margin-top:10px;">
            <div class="left"><i class="icon-yinhao02 yinIcon"></i></div>
            <div class="workDes workDes_5" style="font-size:18px;">{{ studioDesc }}</div>
            <div class="about_des_imgs"></div>
            <div class="right"><i class="icon-yinhao01 yinIcon"></i></div>
          </div>
        </div>
        <div class="contactUs contactUs_5"><span>联系我</span></div><span></span>
        <div class="workCoverWrap" style="display:flex;flex-direction: row;flex-wrap: wrap;justify-content: space-between;align-items: center;">
          <div v-for="item in worksList" :key="item.id" style="width:33.33%;height:300px;overflow: hidden;">
            <router-link :to="{path:'/worksInfo',query: {id: item.id}}">
              <!-- <a class="aboutWorkCover" :style="{backgroundImage:'url('+item.worksCover[0][0]+')'}"></a> -->
              <img class="aboutWorkCover img-fluid" style=":" :src="item.cover" alt="">
            </router-link>
          </div>
        </div>
      </div>
    </div>

      

    <!-- 底部 -->
    <Footer
      :studioName="studioName"
      :weixin="weixin"
      :phone="phone"
      :studioAddress="studioAddress"
      :banner="footBg"
      :wxQrcode="wxcode" />
    <!-- 底部 -->

  </div>
</template>
<script>
import axios from 'axios'
import api from '@/utils/api.js'
import Util from '@/utils/util.js'
import '../../../public/styles/template7.css'
import '../../../public/styles/home.css'
import Menu from '../../components/common/Menu.vue'
import Footer from '../../components/common/Footer.vue'
import { mapActions } from 'vuex'
export default {
  components: {
    Menu,
    Footer
  },
  data() {
    return {
      logoUrl:'',
      bannerList:[],
      studioName:'',
      studioDesc:'',
      studioAddress:'',
      worksList:[],
      goodsList:[],
      video:'',
      phone:'',
      weixin:'',
      wxcode:'',
      footBg:'',
      param:{
        appId:'',
        type:3,
        pageNo:1,
        pageSize:10
      }
    }
  },
  mounted: function () {
    this.GetStudioInfo().then(res=>{
      this.studioInfo = res
      this.appId = this.studioInfo.applet_appid
      this.param.appId = this.appId
      this.init()
      this.getWorksList()
    })

  },
  methods: {
    ...mapActions(['GetStudioInfo']),
    init(){
      this.imgSuffix = Util.getImageThumbSuffix(this.studioInfo.storageType)
    
      this.studioName = this.studioInfo.brand_name
      this.studioDesc = this.studioInfo.brand_desc
      this.studioAddress = this.studioInfo.address
      this.logoUrl = this.studioInfo.logo_url[0][0]
      this.weixin = this.studioInfo.kefu_weixin
      this.phone = this.studioInfo.kefu_phone
      this.video = this.studioInfo.applet_video !== '' ? this.studioInfo.applet_video[0][0] : ''
      this.wxcode = this.studioInfo.corp_qrcode_url[0][0]
      this.footBg = this.studioInfo.banner_img[0][0]
    },

    getWorksList(){
      const that = this
      axios.get(api.GET_WORKS_LIST, {
        params: this.param
      }).then(
        function (response) {
          console.log(response)
          response.data.data.forEach(item => {
            if (item['works_cover'] != null && item['works_cover'] != '') {
              that.worksList.push({
                id: item.id,
                title:item.works_name,
                cover: item['works_cover'][0][0] + that.imgSuffix
              })
            }
          })

          if (response.data.data.length < that.param.pageSize) {
            that.nextTxt = '没有更多内容了'
          } else {
            that.nextTxt = '点击加载更多'
          }

        },
        function (res) {
          console.log(res.data)
        }
      )
    },

   


  }
}
</script>
<style>
  #carousel-fade{
    width: 100%;
    height: 46.875vw;
    position: fixed;
    overflow: hidden;
    margin-top: 70px;
  }
  .nav-a{
    text-decoration: none;
  }
  .studioDesc{
    text-decoration: none;
  }
  </style>
  