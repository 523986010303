var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"footer_container"},[_c('div',{staticClass:"footer_content",style:('background:url( "' + _vm.banner +'") center 59px/cover fixed')},[_c('div',{staticClass:"footer_content_copy bg_white"}),_c('div',{staticClass:"footer_content_wrap"},[_vm._m(0),_c('div',{staticClass:"footer_item footer_item2"},[_c('p',{staticClass:"title",staticStyle:{"font-weight":"900","color":"#fff","font-size":"22px"}},[_vm._v(_vm._s(_vm.studioName))]),_c('div',{staticClass:"detial_info_wrap"},[_c('p',{staticClass:"detial_info",staticStyle:{"display":"flex"}},[_c('span',{staticStyle:{"width":"50px"}},[_vm._v("微信：")]),_vm._v(_vm._s(_vm.weixin))]),_c('p',{staticClass:"detial_info",staticStyle:{"display":"flex"}},[_c('span',{staticStyle:{"width":"50px"}},[_vm._v("电话：")]),_vm._v(_vm._s(_vm.phone))]),_c('p',{staticClass:"detial_info",staticStyle:{"display":"flex"}},[_c('span',{staticStyle:{"width":"50px"}},[_vm._v("地址：")]),_c('span',{staticStyle:{"width":"300px","word-wrap":"break-word","word-break":"break-all","overflow":"hidden"}},[_vm._v(" "+_vm._s(_vm.studioAddress)+" ")])])])]),_c('div',{staticClass:"footer_item footer_item3"},[_vm._m(1),_c('div',[_c('img',{attrs:{"src":_vm.wxQrcode,"alt":"","width":"100px"}}),_vm._m(2)]),_vm._m(3)])])]),_c('div',{staticClass:"footer_bar",staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},[_c('p',{staticClass:"footer_bar_item"},[_vm._v(" COPYRIGHT (©) "+_vm._s(_vm.studioName)+" ")]),_vm._m(4),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer_item footer_item1"},[_c('p',{staticClass:"fir_title",staticStyle:{"font-weight":"900","color":"#fff","font-size":"22px"}},[_c('span',[_vm._v("联系我们")])]),_c('p',{staticClass:"sub_title"},[_vm._v("Contact Us")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"title",staticStyle:{"font-weight":"900","color":"#fff","font-size":"22px"}},[_c('span',[_vm._v("关注")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"width":"100px","text-align":"center","padding":"10px 0"}},[_c('span',[_vm._v("[微信扫码关注]")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"none"}},[_c('div',{staticClass:"icon_wrap"},[_c('i',{staticClass:"icon-weibo03 weiboBtn"})]),_c('div',{staticClass:"icon_wrap"},[_c('i',{staticClass:"icon-wechat03 wechatBtn"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"beian_text"},[_c('a',{staticClass:"beian_number",attrs:{"href":"https://beian.miit.gov.cn","target":"_blank"}},[_c('span')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"footer_bar_item"},[_c('a',{attrs:{"href":"https://saascloud.marryandjoy.com/","target":"_blank"}},[_c('span',[_vm._v("婚悦科技提供技术支持")])])])
}]

export { render, staticRenderFns }