<template>
  <div>
    <b-carousel
      v-if="mode === 0"
      id="carousel-fade"
      style="text-shadow: 0px 0px 2px #000"
      fade
      indicators
      :controls="true"
      label-prev=""
      label-next="">
      <b-carousel-slide v-for="row in bannerList" :key="row.id" :img-src="`${row}`"></b-carousel-slide>
    </b-carousel>
    <div v-if="mode === 1" id="carousel-fade" class="" style="width:100%;height:calc(46.875vw);overflow-x: hidden;">
      <div id="imgBox1" class="" style="width:20000px;height:calc(46.875vw);">
        <div
          class=""
          style="float:left;overflow: hidden;height:calc(46.875vw);"
          :key="index"
          v-for="(item,index) in bannerList">
          <img :src="item" class="img-fluid" style="height: 46.875vw;" />
        </div>
        <div
          class=""
          style="float:left;overflow: hidden;height:calc(46.875vw);"
          :key="index+'_'"
          v-for="(item,index) in moreBanner">
          <img :src="item" class="img-fluid" style="height: 46.875vw;" />
        </div>
      </div>
      <div id="imgBox2">
      </div>
    </div>
    <!-- <div class="letfBtnCss" @click="moveToLeft"><img :src="leftIcon" style="width:20px;" /></div>
        <div class="rightBtnCss" @click="moveToRight"><img :src="rightIcon" style="width:20px;" /></div> -->
    <div v-if="mode === 2" id="carousel-fade" class="" style="display:flex;">
      <div class="" style="overflow: hidden;height:calc(46.875vw);" :key="index" v-for="(item,index) in bannerList">
        <img :src="item" class="img-fluid" style="height: 46.875vw;" />
      </div>
    </div>
  </div>
</template>
<script>

    export default {
        name:'Carousel',
        props:{
          bannerList:Array,
          mode:{
            type:Number,
            default:0
          }
        },
        mounted(){
          
        },
        created(){
          this.init()
        },
        data() {
            return {
              // leftIcon: require('../../../public/img/angle-left.png'),
              // rightIcon: require('../../../public/img/angle-right.png'),
              leftEvent: '',
              rightEvent: '',
              moreBanner:[]
            }
        },
        methods: {
          init(){
            clearInterval(this.leftEvent)
            if (this.bannerList.length <= 4){
              this.moreBanner = this.bannerList
            }
            
            
            setTimeout(() => {
              if (this.mode === 1) {
                console.log('开始滚动')
                this.moveToLeft()
              }
              
            }, 1000)
            
          },
          moveToLeft () {

            var FGgoleft = document.getElementById('carousel-fade')
            var FGgoleft1 = document.getElementById('imgBox1')
            var FGgoleft2 = document.getElementById('imgBox2')

            this.leftEvent = setInterval(function(){

              // console.log(FGgoleft2.offsetWidth, FGgoleft.scrollLeft, FGgoleft1.offsetWidth)
              if (FGgoleft2.offsetWidth - FGgoleft.scrollLeft <= 0) {
                FGgoleft.scrollLeft -= FGgoleft1.offsetWidth
              }  else {
                FGgoleft.scrollLeft++
              }
            },20)
          },
          moveToRight(){
            
          }
        }
    }
</script>

<style scoped>
::v-deep #carousel-fade___BV_inner_ .carousel-inner{
  height: 46.875vw;
}
::v-deep #carousel-fade___BV_inner_ .carousel-item{
  height: 46.875vw;
}
::v-deep #carousel-fade___BV_inner_ .carousel-item img{
  height: 46.875vw;
}
.letfBtnCss {
  position: absolute;
  left: 0;
  top: 50%;
  width: 45px;
  height: 70px;
  color: #666;
  font-size: 25px;
  cursor: pointer;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  line-height: 70px;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.5s;
  background: #e4e4e4;
}
.letfBtnCss:hover,.rightBtnCss:hover{
  opacity: 1 !important;
}
.rightBtnCss {
  position: absolute;
  right: 0;
  top: 50%;
  width: 45px;
  height: 70px;
  color: #666;
  font-size: 25px;
  cursor: pointer;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  line-height: 70px;
  text-align: center;
  opacity:0.5;
  transition: opacity 0.5s;
  background: #e4e4e4;

}
</style>