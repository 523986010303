// 引入路由模块并使用它
import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/view/Index'
import worksInfo from '@/view/info/worksInfo'
import goodsInfo from '@/view/info/goodsInfo'
import caseInfo from '@/view/info/caseInfo'
import worksList from '@/view/list/worksList'
import goodsList from '@/view/list/goodsList'
import caseList from '@/view/list/caseList'
import worksList_black from '@/view/list/black/worksList'
import goodsList_black from '@/view/list/black/goodsList'
import caseList_black from '@/view/list/black/caseList'
import worksInfo_black from '@/view/info/black/worksInfo'
import goodsInfo_black from '@/view/info/black/goodsInfo'
import caseInfo_black from '@/view/info/black/caseInfo'
import about from '@/view/about/index'
Vue.use(VueRouter)

const router = new VueRouter({
  routes:[{
      path: '/',
      name:'Index',
      component: Index,
      meta: {
				title: '首页'
			}
  },{
      path: '/worksInfo',
      name:'worksInfo',
      component: worksInfo,
      meta:{
        title:'作品详情'
      }
  },{
    path: '/about',
    name:'about',
    component: about,
    meta:{
        title:'关于我们'
      }
},{
    path: '/workslist',
    name:'workslist',
    component: worksList,
    meta:{
        title:'作品列表'
      }
},{
  path: '/caselist',
  name:'caseList',
  component: caseList,
  meta:{
      title:'客片动态'
    }
},{
    path: '/goodsList',
    name:'goodsList',
    component: goodsList,
    meta:{
        title:'服务套餐'
      }
},{
    path: '/goodsInfo',
    name:'goodsInfo',
    component: goodsInfo,
    meta:{
        title:'服务详情'
      }
},{
  path: '/caseInfo',
  name:'caseInfo',
  component: caseInfo,
  meta:{
      title:'客片详情'
    }
},{
    path: '/black/workslist',
    name:'workslist_black',
    component: worksList_black,
    meta:{
        title:'作品列表'
      }
},{
    path: '/black/goodsList',
    name:'goodsList_black',
    component: goodsList_black,
    meta:{
        title:'服务套餐'
      }
},{
  path: '/black/caselist',
  name:'caseList_black',
  component: caseList_black,
  meta:{
      title:'客片动态'
    }
},{
      path: '/black/worksInfo',
      name:'worksInfo_black',
      component: worksInfo_black,
      meta:{
        title:'作品详情'
      }
  },{
    path: '/black/goodsInfo',
    name:'goodsInfo_black',
    component: goodsInfo_black,
    meta:{
        title:'服务详情'
      }
},{
  path: '/black/caseInfo',
  name:'caseInfo_black',
  component: caseInfo_black,
  meta:{
      title:'客片详情'
    }
}
]
})

router.beforeEach((to,from,next)=>{//beforeEach是router的钩子函数，在进入路由前执行
  if(to.meta.title){//判断是否有标题
      document.title = to.meta.title
  }
  next()  //执行进入路由，如果不写就不会进入目标页
})

// 输出router
export default router