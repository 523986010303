const Config = require('@/utils/config')

function makeURL(module,module2) {	
	
	if (Config.API_DEBUG) {
			return `http://159.75.235.103:9502/${module}`
		} else {
			return `https://${Config.API_DOMAIN}/${module2}`
		}
	
}

module.exports = {
	
	/**
	 * 获取影楼详情
	 */
	GET_STUDIO_INFO: makeURL('applet/corpinfo/domain', 'applet_corp_domain'),
	
	/**
	 * 获取案例
	 */
	GET_WORKS_LIST: makeURL('applet/service/list', 'appletserverlist'),
	
	/**
	 * 获取套系
	 */
	GET_GOODS_LIST: makeURL('applet/service/list', 'appletserverlist'),
	
	/**
	 * 获取客样
	 */
	GET_CASE_LIST: makeURL('applet/service/list', 'appletserverlist'),
	
	/**
	 * 提交户信息
	 */
	ADD_USER: makeURL('storeuser','storeuser'),
	
	/**
	 * 获取分类
	 */
	GET_CLASS_LIST: makeURL('applet/service/typelist', 'applettypelist'),
	
	/**
	 * 获取分类标签
	 */
	GET_LABEL_LIST: makeURL('applet/service/labellist', 'appletlabellist'),
	
	/**
	 * 获取案例详情
	 */
	GET_WORKS_INFO: makeURL('applet/service/info', 'appletserverinfo'),
	
	/**
	 * 获取样片详情
	 */
	GET_CASE_INFO: makeURL('applet/service/info', 'appletserverinfo'),
	
	/**
	 * 获取套系详情
	 */
	GET_GOODS_INFO: makeURL('applet/service/info', 'appletserverinfo'),
	
	/**
	 * 更新案例查看次数
	 */
	UPDATE_WORKS_VIEW: makeURL('applet/update/number', 'applet_update_viewnumber'),
	
	/**
	 * 更新套系查看次数
	 */
	UPDATE_GOODS_VIEW: makeURL('applet/update/number', 'applet_update_viewnumber'),
	
	/**
	 * 更新套系查看次数
	 */
	UPDATE_CASE_VIEW: makeURL('applet/update/number', 'applet_update_viewnumber'),
	
	/**
	 * 更新案例收藏次数
	 */
	UPDATE_WORKS_LIKE: makeURL('applet/server/updatencollect','server_updatencollect'),
	
	/**
	 * 更新案例收藏次数
	 */
	UPDATE_CASE_LIKE: makeURL('applet/server/updatencollect','server_updatencollect'),
	
	/**
	 * 更新套系收藏次数
	 */
	UPDATE_GOOD_LIKE: makeURL('applet/server/updatencollect','server_updatencollect'),
	
	/**
	 * 添加询价
	 */
	ADD_INQUIRY: makeURL('applet/store/customer', 'storeinquiry'),
	
	/**
	 * 添加用户
	 */
	ADD_USERS: makeURL('adduser_v2', 'adduser_v2'),
	
	/**
	 * 获取用户收藏列表
	 */
	GET_USER_COLLECT: makeURL('applet/users/collection', 'getappletcollection'),
	
	/**
	 * 新增用户访问记录【取消】
	 */
	ADD_USER_VISIT: makeURL('storeuservisit', 'storeuservisit'),
	
	/**
	 * 添加用户订单列表
	 */
	ADD_USER_ORDER: makeURL('applet/store/order', 'appletstoreorder'),
	
	/**
	 * 获取用户订单列表
	 */
	GET_USER_ORDER: makeURL('applet/userorder/index', 'appletorderlist_v2'),
	
	/**
	 * 取消用户订单列表
	 */
	PUT_USER_ORDER: makeURL('applet/update/order', 'appletupdateorder'),
	
	/**
	 * 获取短信验证码
	 */
	GET_SMS_CODE: 'https://wx.marryandjoy.com/wxmobile/DataSetApi/yunSendSms',
	
	/**
	 * 短信验证码登录，手机号不存在则新建
	 */
	ADD_USER_PHONE: makeURL('users/applet/storeuserbyphone', 'storeuserbyphone'),
	
	/**
	 * 获取表结构
	 * type 数据类型 1.套系 2.客片 3.作品 4.作品标签 5.客片标签
	 */
	GET_TABLE_FIELDS: makeURL('applet/info/token', 'appletinfotoken'),
	
	/**
	 * 获取用户是否收藏
	 * type 数据类型 1.套系 2.客片 3.作品
	 */
	GET_USER_COLLECTED: makeURL('applet/collect/info', 'applet_collect_info'),
	
	
	/**
	 * 获取当前门店的小程序码
	 * 
	 */
	GET_APPLET_QRCODE: makeURL('website/applet/qrcode', 'v2_websiteappletcode')
	
	
	

	
}
