<template>
  <div id="index" class="template7_container" style="background:#000;color:#fff;">
    <!-- 头部 -->
    <Menu :actived="2" />
    <!-- 头部 -->

    <div class="template7_blog_sitecontent">
      <div>
        <div>
          <div class="workListWrapTemplate workListWrapTemplate2">
            <div class="workContentWrap">
              <div class="workTile workTile_5" style="color:#fff;"><a>{{ title }}</a></div>
              <!-- <div class="workCreatedAt">October 16, 2022</div> -->
              <div class="work_title_border"></div>
              <pre class="workContent workContent_5">{{ desc }}<a class="more_title_guide"></a></pre>
            </div>

            <div>
              <viewer :images="content" >
                <a class="workImage_wrap workImage_wrap_item" v-for="item in content" :key="item.id">
                  <img v-if="!item.toLowerCase().includes('.mp4')" :src="item" class="workImage" alt="" />
                  <video v-else :src="item" controls controlsList="nodownload" class="workImage"></video>
                </a>
              </viewer>
            </div>

                  
          </div>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <Footer
      :studioName="studioName"
      :weixin="weixin"
      :phone="phone"
      :studioAddress="studioAddress"
      :banner="footBg"
      :wxQrcode="wxcode" />
    <!-- 底部 -->

  </div>
</template>
<script>
import axios from 'axios'
import api from '@/utils/api.js'
import Util from '@/utils/util.js'
import '../../../../public/styles/template7.css'
import '../../../../public/styles/home.css'
import Menu from '@/components/common/Menu.vue'
import Footer from '@/components/common/Footer.vue'
import { mapActions } from 'vuex'
export default {
  components: {
    Menu,
    Footer
  },
  data() {
    return {
      logoUrl:'',
      bannerList:[],
      studioName:'',
      studioDesc:'',
      studioAddress:'',
      video:'',
      phone:'',
      weixin:'',
      info:'',
      contentImg:[],
      banner:[],
      id:'',
      footBg:'',
      wxcode:'',

      imgSuffix:'',
      fields:{},
      param:{},
      appId:'',
      studioInfo:{},
      option:{},
      title:'',
      content:[],
      contentThumb:[],
      desc:''
    }
  },
  mounted: function () {
    this.option = this.$route.query
    this.GetStudioInfo().then(res=>{
      this.studioInfo = res
      this.appId = this.studioInfo.applet_appid
      this.param.appId = this.appId
      this.init()
      this.getInfo()
      
    })

  },
 
  methods: {
    ...mapActions(['GetStudioInfo']),
    init(){
      this.imgSuffix = Util.getImageThumbSuffix(this.studioInfo.storageType)
    
      this.studioName = this.studioInfo.brand_name
      this.studioDesc = this.studioInfo.brand_desc
      this.studioAddress = this.studioInfo.address
      this.logoUrl = this.studioInfo.logo_url !== '' ? this.studioInfo.logo_url[0][0] : ''
      this.weixin = this.studioInfo.kefu_weixin
      this.phone = this.studioInfo.kefu_phone
      this.video = this.studioInfo.applet_video !== '' ? this.studioInfo.applet_video[0][0] : ''
      this.wxcode = this.studioInfo.corp_qrcode_url !== '' ? this.studioInfo.corp_qrcode_url[0][0] : ''
      this.footBg = this.studioInfo.banner_img !== '' ? this.studioInfo.banner_img[0][0] : ''
    },
    getInfo(){
      const that = this
			const param = {
					appId:this.studioInfo.applet_appid,
					type:1,
          id:this.option.id
				}
			axios.get(api.GET_GOODS_INFO,{
				params:param
			}).then(
				function(response) {
          const viewColumn =  response.data.data.viewColumn
          for(const index in viewColumn){
            const fieldInfo = viewColumn[index]
            if(fieldInfo.system  === 1){
              that.fields[fieldInfo.systemFields] = fieldInfo.id
            }
          }
          const info = response.data.data.data[0]

          that.title = info[that.fields.goods_name]
          that.desc = info[that.fields.content_desc]
          that.content = []
          // that.contentThumb = []
          const banner = info[that.fields.goods_cover]
          banner.forEach(img=>{
            that.content.push(img[0])
          })
          const content_img = info[that.fields.content_img]
          content_img.forEach(img=>{
            that.content.push(img[0])
          })
				},
				function(res) {
					console.log(res.data)
				}
			)
    }
    

  }
}
</script>
<style>
  .workImage_wrap_item{
    margin-top: 5px;
  }
  </style>
  