<template>
  <div>
    <!-- 头部 -->
    <Menu :actived="0" />
    <!-- 头部 -->

    <!-- 轮播图 -->
    <Carousel :bannerList="bannerList" :mode="bannerMode"></Carousel>
    <!-- 轮播图 -->

    <div class="template7_inner_container template7_inner_container_black">
      
      
      <!-- 简介 -->
      <div class="template7_description_wrap">
        <router-link class="studioDesc" to="/about">
          <div class="description_wrap">
            <div class="avatar_url">
              <img :src="logoUrl" class="img-fluid" alt="">
            </div>
            <div class="header_des">
              <div class="title">{{ studioName }}</div>
              <div class="sub_title">About Us</div>
              <div class="description_content">
                <p>
                  {{ studioDesc }}
                </p>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <!-- 简介 -->
      
      <!-- 视频 -->
      <div
        class="template7_index_waterfall_wrap template7_index_waterfall_wrap_black template8_video_index_container"
        style="max-width:1200px;"
        v-if="video">
        <div class="des_header">
          <p class="title"><span>VIDEO</span></p>
          <p class="sub_title">视频展示</p>
        </div>
      
      
        <div class="video_wrap" style="overflow:hidden;background:#000;margin-top:3em;">
          <div class="video_is_pfop" style="height:600px">
            <video
              :src="video"
              style="width:100%;height:600px;"
              controls
              controlsList="nodownload"
              autoplay
              loop></video>
          </div>
        </div>
      </div>
      <!-- 视频 -->

      <!-- 案例 -->
      <div class="template7_index_waterfall_wrap template7_index_waterfall_wrap_black">
        
        <div class="des_header">
          <p class="title"><span>作品欣赏</span></p>
          <p class="sub_title">Our Works</p>
        </div>



        <div class="work_list_wrap fadeIn animation2" style="margin-top:3em;">
          <div class="work_list_content" style="transform: translateX(0)">

            <div
              class="work_item_wrap7"
              v-for="(item,index) in worksList"
              :key="index"
            >

              <div class="work_item">
                <a>
                  <div style="width: 100%; overflow: hidden">
                    <div class="cover_url_item" style="height:456px;">
                      <router-link target="_blank" class="router" :to="{ path: '/black/worksInfo', query: { id: item.id }}">
                        <img :src="item.cover" class="img-fluid" alt=""/>
                      </router-link>
                    </div>
                  </div>
                  <div class="title ellipsis" style="padding:10px;text-align:center;">
                    {{ item.name }}
                  </div>
                </a>
              </div>

            </div>

            
          </div>
        </div>

        <router-link class="more_btn2" style="margin:40px auto;" to="/black/worksList">MORE</router-link>
      </div>
      <!-- 案例 -->


      

      

      <!-- 服务套餐 -->
      <div class="template7_index_waterfall_wrap template7_index_waterfall_wrap_black">
        

        <div class="des_header">
          <p class="title"><span>服务套餐</span></p>
          <p class="sub_title">Service exhibition</p>
        </div>

        <div class="work_list_wrap fadeIn animation2" style="margin-top:3em;">
          <div class="work_list_content" style="transform: translateX(0)">

            <div
              class="work_item_wrap"
              v-for="item in goodsList"
              :key="item.id"
            >

            
              <div class="work_item">
                <a>
                  <div style="width: 100%; overflow: hidden">
                    <div class="cover_url_item">
                      <router-link target="_blank" class="router" :to="{ path:'/black/goodsInfo',query: {id: item.id}}">
                        <img :src="item.cover" class="img-fluid" alt=""/>
                      </router-link>
                    </div>
                  </div>
                  <div class="title ellipsis">
                    {{ item.name }}
                  </div>
                  <div class="sub_title">{{ item.price }}</div>
                </a>
              </div>

            </div>

            
          </div>
        </div>

        <router-link class="more_btn2" style="margin:40px auto;" to="/black/goodsList">MORE</router-link>
      </div>
      <!-- 服务套餐 -->

      <!-- 案例 -->
      <div class="template7_index_waterfall_wrap template7_index_waterfall_wrap_black">
      
        <div class="des_header">
          <p class="title"><span>客片动态</span></p>
          <p class="sub_title">New Arrivals</p>
        </div>
      
      
      
        <div class="work_list_wrap fadeIn animation2" style="margin-top:3em;">
          <div class="work_list_content" style="transform: translateX(0);flex-wrap: wrap;">
      
            <div class="work_item_wrap7" style="width:33.333%;padding:0 8px;" v-for="(item,index) in caseList" :key="index">
      
      
              <div class="work_item">
                <a>
                  <div style="width: 100%; overflow: hidden">
                    <div class="cover_url_item" style="height:600px;">
                      <router-link target="_blank" class="router" :to="{ path: '/black/caseInfo', query: { id: item.id }}">
                        <img :src="item.cover" class="img-fluid" alt="" />
                      </router-link>
                    </div>
                  </div>
                  <div class="title ellipsis" style="padding:10px;text-align:center;">
                    {{ item.name }}
                  </div>
                </a>
              </div>
      
            </div>
      
      
          </div>
        </div>
      
        <router-link class="more_btn2" style="margin:40px auto;" to="/black/caselist">MORE</router-link>
      </div>
      <!-- 案例 -->

      

      <!-- 底部 -->
      <Footer
        :studioName="this.studioName"
        :weixin="this.weixin"
        :studioAddress="this.studioAddress"
        :banner="footBg"
        :wxQrcode="wxcode"
      />
      <!-- 底部 -->

    </div>
  </div>
</template>
<script>
import axios from 'axios'
import api from '@/utils/api.js'
import Util from '@/utils/util.js'
import '../../public/styles/template7.css'
import '../../public/styles/home.css'
import Footer from '../components/common/Footer'
import Carousel from '../components/common/Carousel'
import Menu from '../components/common/Menu'

export default {
  name:'Home',
  data() {
    return {
      logoUrl:'',
      bannerList:[],
      studioName:'',
      studioDesc:'',
      studioAddress:'',
      goodsList:[],
      video:'',
      weixin:'',
      worksList:[],
      caseList:[],
      codeShow:0,
      typeList:[],
      footBg:'',
      wxcode:'',
      bannerMode:0
    }
  },
  components:{
    Footer,
    Carousel,
    Menu
  },
  mounted: function () {
    this.studioInfo = this.$store.state.info
		this.init()
  },
  methods: {
    init(){
			this.imgSuffix = Util.getImageThumbSuffix(this.studioInfo.storageType)
			this.getBannerList()
			this.studioName = this.studioInfo.brand_name
      this.studioDesc = this.studioInfo.brand_desc
			this.studioAddress = this.studioInfo.address
			this.weixin = this.studioInfo.kefu_weixin
			this.phone = this.studioInfo.kefu_phone
			this.bannerMode = Number(this.studioInfo.banner_type)
      try {
        this.logoUrl = this.studioInfo.logo_url[0][0]
      } catch (error) {
        console.log(error);
      }
      try {
        this.video = this.studioInfo.applet_video[0][0]
      } catch (error) {
        console.log(error);
      }
      try {
        this.wxcode = this.studioInfo.corp_qrcode_url[0][0]
      } catch (error) {
        console.log(error);
      }
      try {
        this.footBg = this.studioInfo.banner_img[0][0]
      } catch (error) {
        console.log(error);
      }
      this.getTypeList()
			this.getWorksList()
      this.getCaseList()
			this.getGoodsList()
		},
		getBannerList(){
			this.bannerList = []
			this.studioInfo.banner_img.forEach(item=>{
				this.bannerList.push(item[0] + this.imgSuffix)
			})
		},
		getWorksList(){
      const that = this
			const param = {
					appId:this.studioInfo.applet_appid,
					type:3,
					pageNo:1,
					pageSize:8
				}
			axios.get(api.GET_WORKS_LIST,{
				params:param
			}).then(
				function(response) {
					that.worksList = []
					response.data.data.forEach(item=>{
						if(item['works_cover'] != null && item['works_cover'] != ''){
							that.worksList.push({
								id:item.id,
								cover:item['works_cover'][0][0] + that.imgSuffix
							})
						}
					})
				},
				function(res) {
					console.log(res.data)
				}
			)
		},
    getCaseList(){
      const that = this
			const param = {
					appId:this.studioInfo.applet_appid,
					type:2,
					pageNo:1,
					pageSize:6
				}
			axios.get(api.GET_CASE_LIST,{
				params:param
			}).then(
				function(response) {
					that.caseList = []
					response.data.data.forEach(item=>{
						if(item['works_cover'] != null && item['works_cover'] != ''){
							that.caseList.push({
								id:item.id,
								cover:item['works_cover'][0][0] + that.imgSuffix
							})
						}
					})
				},
				function(res) {
					console.log(res.data)
				}
			)
		},
		getGoodsList(){
      const that = this
			const param = {
					appId:this.studioInfo.applet_appid,
					type:1,
					pageNo:1,
					pageSize:6
				}
			axios.get(api.GET_GOODS_LIST,{
				params:param
			}).then(
				function(response) {
					that.goodsList = []
					response.data.data.forEach(item=>{
						if(item['goods_cover'] != null && item['goods_cover'] != ''){
							that.goodsList.push({
								id:item.id,
								cover:item['goods_cover'][0][0] + that.imgSuffix,
                name:item.goods_name,
                price:item.sell_price
							})
						}
					})
				},
				function(res) {
					console.log(res.data)
				}
			)
		},
    getTypeList(){
      const that = this
			const param = {
					appId:this.studioInfo.applet_appid,
					type:3,
					pageNo:1,
					pageSize:3
				}
			axios.get(api.GET_CLASS_LIST,{
				params:param
			}).then(
				function(response) {
					console.log('response:::',response)
					const fields = {}
          response.data.data.fields.forEach(item => {
            if (item.system == 1) {
              fields[item.systemFields] = item.dataIndex
            }
          })
          if (response.data.data.data.length != 0) {
          
            response.data.data.data.forEach((item) => {
              if(item[fields['class_img']] != null && item[fields['class_img']] != ''){
                const classItem = {
                  id: item.id,
                  name: item[fields['class_name']],
                  cover: item[fields['class_img']][0][0]
                }
                that.typeList.push(classItem)
              }
            })
          }
				},
				function(res) {
					console.log(res.data)
				}
			)
    }
  }
}
</script>
<style>
#carousel-fade{
  width: 100%;
  height: 46.875vw;
  position: fixed;
  overflow: hidden;
  margin-top: 70px;
}
.nav-a{
  text-decoration: none;
}
.studioDesc{
  text-decoration: none;
}
.more_btn{
  text-decoration: none;
}
.more_btn2{
    width: 218px;
    height: 44px;
    line-height: 44px;
    font-size: 15px;
    color: #bdbdbd;
    margin: 0 auto 60px;
    text-align: center;
    border: 1px solid #dfdfdf;
    display: block;
    transition: all 0.3s;
    text-decoration: none;
}
.more_btn2:hover{
    color: #fff;
    background: #bdbdbd;
}
a{
  text-decoration: none !important;
}
.template7_inner_container_black{
  background: #000;
}
.template7_index_waterfall_wrap_black .title{
  color: #bdbdbd !important;
}
</style>
