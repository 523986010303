import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import api from '@/utils/api.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    appId:'',
    logoUrl: '',
    weixin: '',
    studioName: '',
    sid: '',
    studioAddress:'',
    domain:'',
    tpl:'1',
    info:[]
  },
  mutations: {
    SET_APPID: (state, appId) => {
    state.appId = appId
    },
    SET_LOGO: (state, logoUrl) => {
    state.logoUrl = logoUrl
    },
    SET_WEIXIN: (state, weixin) => {
    state.weixin = weixin
    },
    SET_STUDIONAME: (state, studioName) => {
    state.studioName = studioName
    },
    SET_ADDRESS: (state, studioAddress) => {
    state.studioAddress = studioAddress
    },
    SET_DOMAIN: (state, domain) => {
    state.domain = domain
    },
    SET_TPL: (state, tpl) => {
    state.tpl = tpl
    },
    SET_INFO: (state, info) => {
    state.info = info
    },
  },
  actions: {
    GetStudioInfo({commit}){
      return new Promise((resolve, reject) => {
        const domain = document.domain
        // const domain = 'www.baidu.com'
        const param = {}
        param.domain = domain
          axios.get(api.GET_STUDIO_INFO,{
            params:param
          }).then(
              function(response) {
                  commit('SET_APPID', response.data.data.applet_appid)
                  commit('SET_LOGO', response.data.data.logo_url[0][0])
                  commit('SET_WEIXIN', response.data.data.kefu_weixin)
                  commit('SET_STUDIONAME', response.data.data.brand_name)
                  commit('SET_ADDRESS', response.data.data.address)
                  commit('SET_TPL', response.data.data.applet_tpl)
                  commit('SET_INFO', response.data.data)
                  document.title = response.data.data.brand_name + ' | ' + document.title
                  resolve(response.data.data)
              },
              function(res) {
                  console.log(res.data)
                  reject()
              }
          )
      })
      
    }
  },
})


